'use strict';

$(document).ready(function () {

    // Menu
    var menu;

    menu = new Menu();

    // Object fit Modernizr

    if (!Modernizr.objectfit) {
        $('.object-fit').each(function () {
            var $container = $(this),
                imgUrl = $container.find('img').prop('src');
            if (imgUrl) {
                $container.css('backgroundImage', 'url(' + imgUrl + ')').addClass('compat-object-fit');
            }
        });
    }

    // Scrollto a:href

    $('a[href*=\\#]:not([href=\\#])').each(function () {
        $(this).click(function (event) {
            event.preventDefault();
            var to = $.attr(this, 'href');
            var st = $(to).offset().top;
            if (st <= 50) st = 0;
            if ($(to).offset()) {
                $('html, body').animate({
                    scrollTop: $(to).offset().top - 60
                }, 1200, 'easeInOutExpo', function () {});
            }
            return false;
        });
    });

    // Sticky nav + waypoint

    if ($('.sticky-nav nav').length > 0) {

        var sticky = new Waypoint.Sticky({
            element: $('.sticky-nav nav')[0],
            offset: 0
        });

        var sections = $('section');
        var navigation = $('.sticky-nav').find('nav');

        sections.waypoint({
            handler: function handler(direction) {
                var index = 0;
                if (direction == 'down') {
                    index = $($(this)[0].element).index();
                } else {
                    index = $($(this)[0].element).index() - 1;
                }
                if (index < 0) index = 0;

                var $this = $(this);
                navigation.find('a').removeClass('active');
                navigation.find('a[href="#' + $('section').eq(index).attr('id') + '"]').addClass('active');
            },
            offset: '50%'
        });
    }
});

$(window).on('load', function () {

    // Img reveal

    var imgReveal = {
        origin: 'bottom',
        duration: 600,
        distance: '16px',
        easing: 'ease-in-out',
        scale: 1,
        opacity: 0
    };

    window.sr = ScrollReveal();
    sr.reveal('.img-reveal', imgReveal);
});
"use strict";
'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Menu = function () {
    function Menu() {
        _classCallCheck(this, Menu);

        this.evt = 'touchstart';
        if (navigator.msPointerEnabled || !('ontouchend' in window)) this.evt = "click";

        jQuery('#hamburger').on(this.evt, function (e) {
            if (jQuery('html').hasClass('hamburger-eaten')) {
                this.close();
            } else {
                this.open();
            }
        }.bind(this));
    }

    _createClass(Menu, [{
        key: 'open',
        value: function open() {
            jQuery('html').addClass('hamburger-eaten');
        }
    }, {
        key: 'close',
        value: function close() {
            jQuery('html').addClass('hamburger-renew');
            jQuery('html').removeClass('hamburger-eaten');
            setTimeout(function () {
                jQuery('html').removeClass('hamburger-renew');
            }, 1000);
        }
    }]);

    return Menu;
}();