class Menu {
    constructor() {
        this.evt = 'touchstart';
        if (navigator.msPointerEnabled || !('ontouchend' in window)) this.evt = "click";

        jQuery('#hamburger').on(this.evt,function(e) {
            if (jQuery('html').hasClass('hamburger-eaten')){
                this.close();
            }else{
                this.open();
            }
        }.bind(this));
    }

    open(){
        jQuery('html').addClass('hamburger-eaten');
    }

    close(){
        jQuery('html').addClass('hamburger-renew');
        jQuery('html').removeClass('hamburger-eaten');
        setTimeout(function(){
            jQuery('html').removeClass('hamburger-renew');
        },1000);
    }
}