$( document ).ready(function() {

    // Menu
    var menu;

    menu = new Menu();





    // Object fit Modernizr

    if ( ! Modernizr.objectfit ) {
        $('.object-fit').each(function () {
            var $container = $(this),
                imgUrl = $container.find('img').prop('src');
            if (imgUrl) {
                $container
                    .css('backgroundImage', 'url(' + imgUrl + ')')
                    .addClass('compat-object-fit');
            }
        });
    }




    // Scrollto a:href

    $('a[href*=\\#]:not([href=\\#])').each(function(){
        $(this).click(function(event){
            event.preventDefault();
            var to = $.attr(this, 'href');
            var st =  $(to).offset().top;
            if (st <= 50) st = 0;
            if ($(to).offset()){
                $('html, body').animate({
                    scrollTop: ($(to).offset().top) - 60
                }, 1200, 'easeInOutExpo', function () {
                });
            }
            return false;
        });
    });





    // Sticky nav + waypoint

    if ($('.sticky-nav nav').length > 0){

        var sticky = new Waypoint.Sticky({
            element: $('.sticky-nav nav')[0],
            offset: 0
        });

        var sections = $('section');
        var navigation = $('.sticky-nav').find('nav');

        sections.waypoint({
            handler: function(direction) {
                var index = 0;
                if (direction == 'down'){
                    index = $($(this)[0].element).index();
                }else{
                    index = $($(this)[0].element).index()-1;
                }
                if (index < 0 ) index = 0;

                var $this = $(this);
                navigation.find('a').removeClass('active');
                navigation.find('a[href="#'+ $('section').eq(index).attr('id') +'"]').addClass('active');
            },
            offset: '50%'
        });
    }



});

$(window).on('load', function() {

    // Img reveal

    var imgReveal = {
        origin: 'bottom',
        duration: 600,
        distance : '16px',
        easing   : 'ease-in-out',
        scale: 1,
        opacity: 0
    };

    window.sr = ScrollReveal();
    sr.reveal('.img-reveal', imgReveal);

});
